import dynamic from 'next/dynamic';

import { BannerSliceProps } from '@ui/slice-machine/slices/banner-slice/BannerSlice';

const BannerSlice = dynamic<BannerSliceProps>(() =>
  import('@ui/slice-machine/slices/banner-slice/BannerSlice').then(
    (val) => val.BannerSlice
  )
);

export default BannerSlice;
